<h2 mat-dialog-title>The following documents will not be uploaded</h2>

<mat-dialog-content>
  <ul class="errors">
    <li *ngFor="let file of data.filesWithErrors" [tp]="file.tooltip">
      {{ file.formGroup.controls["name"].value }}.{{ file.extension }}
      <mat-icon>help</mat-icon>
    </li>
  </ul>
  <p class="text-center">Would you like to continue?</p>
</mat-dialog-content>

<mat-dialog-actions>
  <iov-button-group>
    <iov-button (pressed)="onClose()" type="ghost">Cancel</iov-button>
    <iov-button (pressed)="onClose(true)" cdkFocusInitial>
      <mat-icon icon-left>check</mat-icon>
      Continue
    </iov-button>
  </iov-button-group>
</mat-dialog-actions>
