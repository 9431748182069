import { ChangeDetectionStrategy, Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { UploadFileErrorTypeEnum } from "src/app/shared/enums";

@Component({
  templateUrl: "./validate-upload-dialog.component.html",
  styleUrls: ["./validate-upload-dialog.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ValidateUploadDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ValidateUploadDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      filesWithErrors: any[];
    },
  ) {
    for (const file of this.data.filesWithErrors) {
      let tooltip = "";

      for (const error of file.errors) {
        tooltip += !tooltip ? "" : ". ";
        switch (error.type) {
          case UploadFileErrorTypeEnum.TOO_LARGE:
            tooltip += `File is too large (${error.size}MB)`;
            break;
          case UploadFileErrorTypeEnum.FILE_EXTENSION:
            tooltip += `File extension not supported (${error.extension})`;
            break;
          case UploadFileErrorTypeEnum.DUPLICATED_NAME:
            tooltip += `This name is already being used by another document`;
            break;
        }
      }
      file.tooltip = tooltip;
    }
  }

  public onClose = (hasConfirmed = false): void => {
    this.dialogRef.close({ hasConfirmed });
  };
}
