<mat-accordion>
  <mat-expansion-panel class="mat-elevation-z0 section-box" [expanded]="false">
    <mat-expansion-panel-header class="section-header">
      <mat-panel-title class="section-title">
        <div class="location-country-group">
          <app-flag [country]="organisationsByCountry().value[0].address.country"></app-flag>
          <span class="country-name">
            {{ countryName }} ({{ organisationsByCountry().value.length }})
          </span>
        </div>
      </mat-panel-title>
    </mat-expansion-panel-header>
    @for (organisation of organisationsByCountry().value; track organisation.id) {
      <app-delivery-report-organisation-details
        [organisation]="organisation"
      ></app-delivery-report-organisation-details>
    }
  </mat-expansion-panel>
</mat-accordion>
