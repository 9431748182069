import { CommonModule, DatePipe, DecimalPipe } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { GoogleMapsModule } from "@angular/google-maps";
import { RouterModule } from "@angular/router";

import {
  popperVariation,
  provideTippyConfig,
  TippyDirective,
  tooltipVariation,
} from "@ngneat/helipopper";
import { AgGridModule } from "ag-grid-angular";
import { AngularMaterialModule } from "src/app/angular-material.module";
import {
  ButtonsCellRendererComponent,
  DateRangeCellRendererComponent,
  EnumsCellRendererComponent,
  FirstExistingValueCellRendererComponent,
  LinkCellRendererComponent,
  QuickActionsMenuComponent,
  RulesetIconNameCellRendererComponent,
  ValueWithTooltipCellRendererComponent,
  SlideOverCellRendererComponent,
  UnitOfMeasurementCellRendererComponent,
  BadgeLinkCellRendererComponent,
  ColumnHeaderRendererComponent,
  ChipsCellRendererComponent,
  RoundNumberCellRendererComponent,
  InputCellRendererComponent,
} from "src/app/shared/cell-renderers";
import { ContentProjectionDirective, DragCursorDirective } from "src/app/shared/directives";
import { ConditionsMetCheckerPipe } from "src/app/shared/pipes";

import { FormLinkComponent } from "@design-makeover/components/form/form-link/form-link.component";
import { DesignMakeoverModule } from "@design-makeover/design-makeover.module";
import {
  AutocompletePositionUpdateDirective,
  BackNavigationDirective,
  NoScrollInputDirective,
} from "@design-makeover/directives";
import { UnitConversionPipe } from "@design-makeover/pipes/unit-conversion.pipe";
import { UnitSymbolPipe } from "@design-makeover/pipes/unit-symbol.pipe";

import { DeliveryReportOrganisationsByCountryComponent } from "@components/reports/delivery-report/organisation-certificates-documents/delivery-report-organisations-by-country.component";
import { LoaderComponent } from "@components/shared/loader/loader.component";
import { AttachProductCellRendererComponent } from "@shared/cell-renderers/attach-product/attach-product.cell-renderer.component";

import {
  UploadDocumentsButtonsComponent,
  UploadDocumentsComponent,
  ValidateUploadDialogComponent,
} from "./upload-documents";
import {
  AddAnalysisDialogComponent,
  AddConnectionDialogComponent,
  AddItemDialogComponent,
  AddLocationDialogComponent,
  AddProcessInputsOutputsComponent,
  AddProcessSelectedItemsComponent,
  AddUserDialogComponent,
  AdminOrganisationDetailsComponent,
  AdminOrganisationsComponent,
  AdminLocationDetailsComponent,
  CertificateOverlayComponent,
  SharedCertificateOverlayComponent,
  DeliveryOverlayComponent,
  DocumentOverlayComponent,
  EditCustomFieldDialogComponent,
  EditDocumentMainDetailsComponent,
  EditDocumentTypeDialogComponent,
  EditItemQuantityDialogComponent,
  EditLocationTypeDialogComponent,
  AddMaterialDialogComponent,
  EditOrganisationMainDetailsComponent,
  EditProcessTypeDialogComponent,
  EditStandardDialogComponent,
  EditTagDialogComponent,
  EditUserDialogComponent,
  ForgotPasswordComponent,
  GetTypeRecordState,
  InvitationsComponent,
  ItemOverlayComponent,
  LocationOverlayAddressCheckboxComponent,
  LocationOverlayAddressComponent,
  LocationOverlayComponent,
  SharedLocationOverlayComponent,
  LocationOverlayGpsComponent,
  LocationOverlayLinksComponent,
  LocationOverlayMainInformationComponent,
  LogoutComponent,
  OrganisationOverlayComponent,
  SharedOrganisationOverlayComponent,
  ProcessInputsOutputsComponent,
  ProcessOverlayComponent,
  ProcessSelectedItemsComponent,
  ProcessTypesTableComponent,
  ReportsEudrComponent,
  ReportsEudrProductionPlaceComponent,
  ReportsEudrProducerInfoComponent,
  ResetPasswordComponent,
  SettingsStandardsComponent,
  SharingComponent,
  SignUpComponent,
  SlideOverlayDeliveryItemsComponent,
  UserSettingsLanguageDialogComponent,
  VerifyComponent,
  ReportsEudrCommodityInfoComponent,
  InboxComponent,
  InboxTableComponent,
  AddProductDialogComponent,
  ProductOverlayComponent,
  RelatedProductsComponent,
  TransferOrMapDataComponent,
  TrasferOrMapFormComponent,
  SharedLinkComponent,
  ExternalLinkIconComponent,
  AnalysesTableComponent,
  MaterialOverlayComponent,
  BulkAddItemsComponent,
  BulkAddItemsSetValuesComponent,
  BulkAddItemsEnterItemsComponent,
  BulkAddItemsCreateItemsComponent,
  SharedDocumentOverlayComponent,
  BulkAddItemsEditFieldDialogComponent,
  AddCertificateDialogComponent,
  CertificateOverlayMainInformationComponent,
  ReportsEudrMaterialInfoComponent,
  BulkAddSlideOverComponent,
  DeliveryReportComponent,
  SharedSupplyChainComponent,
  SharedDeliveryOverlayComponent,
  EditDeliveryMainDetailsComponent,
  DeliveryReportProcessesComponent,
  DeliveryReportProcessInfoComponent,
  DeliveryReportSupplyChainEntitiesComponent,
  DeliveryReportSupplyChainEntityComponent,
  DeliveryReportMainLocationsTableComponent,
  AddDocumentDialogComponent,
  RiskAssessmentTemplateOverlayComponent,
  RiskAssessmentTemplateOverlayIndicatorsComponent,
  DragDropListContainerComponent,
  DragDropListItemComponent,
  DeliveryReportLocationDetailComponent,
  DeliveryReportLocationDetailsComponent,
  DeliveryReportLocationTypeGroupComponent,
  DeliveryReportCertificatesSummaryComponent,
  DeliveryReportLocationCountryGroupComponent,
  DeliveryReportOrganisationDetailsComponent,
  DeliveryReportOrganisationCertificatesDocumentsComponent,
  AddEditRiskLevelSetDialogComponent,
  DeliveryReportOtherDeliveriesComponent,
  DeliveryReportOtherDeliveriesByGroupComponent,
  DeliveryReportOtherDeliveriesDeliveryDetailsComponent,
} from "../../components";
import {
  AcceptInvitationDialogComponent,
  AddOrganisationDialogComponent,
  AdminOrganisationExtensionTableComponent,
  AdminOrganisationsTableComponent,
  AnalysisDetailsSlideOverComponent,
  AttachmentsTableComponent,
  CertificateListItemsComponent,
  CertificatesTableComponent,
  CommentsComponent,
  ConfirmDialogComponent,
  ConnectionsTableComponent,
  CopyTextButtonComponent,
  CustomFieldsDetailsComponent,
  CustomFieldsEditComponent,
  CustomFieldsTableComponent,
  DeliveriesTableComponent,
  DocumentListItemsComponent,
  DocumentsTableComponent,
  DocumentTypesTableComponent,
  EntitySaveFooterComponent,
  EntityTagsComponent,
  FlagComponent,
  FlowChartNodeComponent,
  InfoDialogComponent,
  InvitationsTableComponent,
  ItemsListItemsComponent,
  ItemsSupplyChainComponent,
  ItemsSupplyChainMapComponent,
  ItemsTableComponent,
  LocationListItemsComponent,
  LocationsTableComponent,
  LocationTypesTableComponent,
  MapComponent,
  MaterialsListItemsComponent,
  MaterialsTableComponent,
  MembershipsTableComponent,
  MoreActionsButtonsComponent,
  NotSharedDataComponent,
  OverlayCertificateAttachmentsComponent,
  OverlayDocumentAttachmentsComponent,
  PageTitleComponent,
  PasswordRequirementsComponent,
  ProcessesTableComponent,
  ProcessInOutTableComponent,
  ProcessSelectedItemsTableComponent,
  ProductListItemsComponent,
  ProductsTableComponent,
  ProductSupplyChainComponent,
  QuantitySummaryTableComponent,
  RightSidePanelNoItemsComponent,
  RulesetsTableComponent,
  SearchAndFiltersComponent,
  SelectOrganisationDialogComponent,
  ShareButtonComponent,
  ShareDialogComponent,
  SharesTableComponent,
  StandardsTableComponent,
  SupplyChainFlowChartComponent,
  SupplyChainMapComponent,
  SupplyChainsTableComponent,
  TableComponent,
  TagsSelectorComponent,
  TagsSelectorDialogComponent,
  TagsTableComponent,
  UnitsOfMeasurementListItemsComponent,
  UnitsOfMeasurementTableComponent,
  UsersInvitedTableComponent,
  UsersTableComponent,
  ValueAndCopyButtonComponent,
  FullScreenSupplyChainDialogComponent,
  EditOrganisationConnectionDialogComponent,
  SendDialogComponent,
} from "../../components/shared";

const components = [
  LocationOverlayAddressCheckboxComponent,
  LocationOverlayLinksComponent,
  LocationOverlayAddressComponent,
  LocationOverlayMainInformationComponent,
  ForgotPasswordComponent,
  ResetPasswordComponent,
  SharingComponent,
  SignUpComponent,
  VerifyComponent,
  UploadDocumentsComponent,
  UploadDocumentsButtonsComponent,
  ValidateUploadDialogComponent,
  QuantitySummaryTableComponent,
  SupplyChainFlowChartComponent,
  CertificatesTableComponent,
  DeliveriesTableComponent,
  DocumentsTableComponent,
  ItemsTableComponent,
  UserSettingsLanguageDialogComponent,
  AddMaterialDialogComponent,
  EditDocumentTypeDialogComponent,
  AddUserDialogComponent,
  LocationsTableComponent,
  MaterialsTableComponent,
  DocumentTypesTableComponent,
  UsersTableComponent,
  PageTitleComponent,
  AdminOrganisationsComponent,
  AdminLocationDetailsComponent,
  AdminOrganisationDetailsComponent,
  ConfirmDialogComponent,
  CopyTextButtonComponent,
  InfoDialogComponent,
  MaterialsListItemsComponent,
  CertificateListItemsComponent,
  DocumentListItemsComponent,
  LocationListItemsComponent,
  ItemsListItemsComponent,
  DragCursorDirective,
  ContentProjectionDirective,
  FlagComponent,
  RightSidePanelNoItemsComponent,
  AddConnectionDialogComponent,
  MapComponent,
  PasswordRequirementsComponent,
  FlowChartNodeComponent,
  InvitationsTableComponent,
  InvitationsComponent,
  AddOrganisationDialogComponent,
  SelectOrganisationDialogComponent,
  AcceptInvitationDialogComponent,
  AttachmentsTableComponent,
  MoreActionsButtonsComponent,
  SearchAndFiltersComponent,
  EditLocationTypeDialogComponent,
  LocationTypesTableComponent,
  EditProcessTypeDialogComponent,
  ProcessTypesTableComponent,
  ProcessesTableComponent,
  ProcessInOutTableComponent,
  ProcessSelectedItemsTableComponent,
  SettingsStandardsComponent,
  StandardsTableComponent,
  EditStandardDialogComponent,
  GetTypeRecordState,
  AddAnalysisDialogComponent,
  AnalysisDetailsSlideOverComponent,
  EntitySaveFooterComponent,
  ItemsSupplyChainComponent,
  UsersInvitedTableComponent,
  EditUserDialogComponent,
  TableComponent,
  TagsSelectorComponent,
  TagsSelectorDialogComponent,
  RulesetsTableComponent,
  ConditionsMetCheckerPipe,
  LinkCellRendererComponent,
  BadgeLinkCellRendererComponent,
  ColumnHeaderRendererComponent,
  ChipsCellRendererComponent,
  FirstExistingValueCellRendererComponent,
  ButtonsCellRendererComponent,
  ConnectionsTableComponent,
  SupplyChainsTableComponent,
  ProductsTableComponent,
  MembershipsTableComponent,
  AdminOrganisationsTableComponent,
  DateRangeCellRendererComponent,
  RoundNumberCellRendererComponent,
  SlideOverCellRendererComponent,
  AttachmentsTableComponent,
  UnitOfMeasurementCellRendererComponent,
  RulesetIconNameCellRendererComponent,
  QuickActionsMenuComponent,
  AttachProductCellRendererComponent,
  ShareButtonComponent,
  ShareDialogComponent,
  SharesTableComponent,
  ProductListItemsComponent,
  NotSharedDataComponent,
  SupplyChainMapComponent,
  LogoutComponent,
  LocationOverlayGpsComponent,
  ProductSupplyChainComponent,
  LocationOverlayComponent,
  SharedLocationOverlayComponent,
  EnumsCellRendererComponent,
  UnitsOfMeasurementTableComponent,
  UnitSymbolPipe,
  EditCustomFieldDialogComponent,
  CustomFieldsTableComponent,
  CustomFieldsDetailsComponent,
  CustomFieldsEditComponent,
  DocumentOverlayComponent,
  EditDocumentMainDetailsComponent,
  OrganisationOverlayComponent,
  SharedOrganisationOverlayComponent,
  EditOrganisationMainDetailsComponent,
  EnumsCellRendererComponent,
  AddLocationDialogComponent,
  CertificateOverlayComponent,
  OverlayDocumentAttachmentsComponent,
  OverlayCertificateAttachmentsComponent,
  DeliveryOverlayComponent,
  SlideOverlayDeliveryItemsComponent,
  EditItemQuantityDialogComponent,
  EditTagDialogComponent,
  TagsTableComponent,
  UnitsOfMeasurementListItemsComponent,
  AddItemDialogComponent,
  ItemsSupplyChainMapComponent,
  CommentsComponent,
  AdminOrganisationExtensionTableComponent,
  ProcessSelectedItemsComponent,
  AddProcessInputsOutputsComponent,
  AddProcessSelectedItemsComponent,
  ProcessInputsOutputsComponent,
  ProcessOverlayComponent,
  ItemOverlayComponent,
  FullScreenSupplyChainDialogComponent,
  ValueWithTooltipCellRendererComponent,
  EditOrganisationConnectionDialogComponent,
  SendDialogComponent,
  ReportsEudrComponent,
  ReportsEudrProductionPlaceComponent,
  ReportsEudrProducerInfoComponent,
  ReportsEudrMaterialInfoComponent,
  ReportsEudrCommodityInfoComponent,
  InboxComponent,
  InboxTableComponent,
  AddProductDialogComponent,
  ProductOverlayComponent,
  RelatedProductsComponent,
  TransferOrMapDataComponent,
  TrasferOrMapFormComponent,
  SharedLinkComponent,
  AnalysesTableComponent,
  MaterialOverlayComponent,
  BulkAddItemsComponent,
  BulkAddItemsSetValuesComponent,
  BulkAddItemsEnterItemsComponent,
  BulkAddItemsCreateItemsComponent,
  SharedDocumentOverlayComponent,
  BulkAddItemsEditFieldDialogComponent,
  AddCertificateDialogComponent,
  SharedCertificateOverlayComponent,
  CertificateOverlayMainInformationComponent,
  BulkAddSlideOverComponent,
  DeliveryReportComponent,
  SharedSupplyChainComponent,
  SharedDeliveryOverlayComponent,
  EditDeliveryMainDetailsComponent,
  DeliveryReportProcessesComponent,
  DeliveryReportProcessInfoComponent,
  DeliveryReportSupplyChainEntitiesComponent,
  DeliveryReportSupplyChainEntityComponent,
  DeliveryReportMainLocationsTableComponent,
  AddDocumentDialogComponent,
  RiskAssessmentTemplateOverlayComponent,
  RiskAssessmentTemplateOverlayIndicatorsComponent,
  DragDropListContainerComponent,
  DragDropListItemComponent,
  InputCellRendererComponent,
  DeliveryReportLocationDetailComponent,
  DeliveryReportLocationDetailsComponent,
  DeliveryReportLocationTypeGroupComponent,
  DeliveryReportCertificatesSummaryComponent,
  DeliveryReportLocationCountryGroupComponent,
  DeliveryReportOrganisationCertificatesDocumentsComponent,
  DeliveryReportOrganisationsByCountryComponent,
  DeliveryReportOrganisationDetailsComponent,
  AddEditRiskLevelSetDialogComponent,
  DeliveryReportOtherDeliveriesComponent,
  DeliveryReportOtherDeliveriesByGroupComponent,
  DeliveryReportOtherDeliveriesDeliveryDetailsComponent,
];

@NgModule({
  declarations: [...components],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    GoogleMapsModule,
    TippyDirective,
    AngularMaterialModule,
    AgGridModule,
    DesignMakeoverModule,
    LoaderComponent,
    EntityTagsComponent,
    ValueAndCopyButtonComponent,
    ExternalLinkIconComponent,
    AutocompletePositionUpdateDirective,
    NoScrollInputDirective,
    FormLinkComponent,
    BackNavigationDirective,
  ],
  exports: [...components, TippyDirective],
  providers: [
    DatePipe,
    DecimalPipe,
    UnitConversionPipe,
    provideTippyConfig({
      defaultVariation: "whiteTooltip",
      variations: {
        tooltip: tooltipVariation,
        popper: popperVariation,
        whiteTooltip: {
          ...tooltipVariation,
          theme: "white",
          arrow: true,
        },
        whiteTooltipNoArrow: {
          ...tooltipVariation,
          theme: "white",
          arrow: false,
        },
      },
    }),
  ],
})
export class ShareComponentsModule {}
