import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from "@angular/core";

import { UploadStepEnum } from "src/app/shared/enums";

@Component({
  selector: "app-upload-documents-buttons",
  templateUrl: "./upload-documents-buttons.component.html",
  styleUrls: ["./upload-documents-buttons.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UploadDocumentsButtonsComponent {
  @Input() step: UploadStepEnum;

  @Input() doneButtonText = "Done";

  @Input() isSubmitButtonDisabled = true;

  @Input() isShowCancelButton = true;

  @Input() isDoneButtonDisabled = true;

  @Output() cancel: EventEmitter<void> = new EventEmitter();

  @Output() tryUploadAll: EventEmitter<void> = new EventEmitter();

  @Output() retryAll: EventEmitter<void> = new EventEmitter();

  @Output() done: EventEmitter<void> = new EventEmitter();

  get initial(): boolean {
    return [UploadStepEnum.SELECT_FILES].includes(this.step);
  }

  get hasError(): boolean {
    return [UploadStepEnum.UPLOAD_END_ERROR, UploadStepEnum.ATTACH_END_ERROR].includes(this.step);
  }

  get finished(): boolean {
    return [
      UploadStepEnum.UPLOAD_END_ERROR,
      UploadStepEnum.UPLOAD_END_SUCCESS,
      UploadStepEnum.ATTACH_END_SUCCESS,
      UploadStepEnum.ATTACH_END_ERROR,
    ].includes(this.step);
  }
}
