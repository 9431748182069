<div [ngClass]="containerClass">
  <div *ngIf="title" class="container-space-between">
    <app-page-title
      icon="description"
      [title]="title"
      [goBackRoute]="isShowBackButton ? routingEnum.DOCUMENTS : null"
    ></app-page-title>
    <iov-button-group [visible]="isShowTitleButtons">
      <app-upload-documents-buttons
        [step]="step"
        doneButtonText="Done"
        [isSubmitButtonDisabled]="isSubmitButtonDisabled()"
        [isDoneButtonDisabled]="isDoneButtonDisabled()"
        (cancel)="onCancel()"
        (tryUploadAll)="onTryUploadAll()"
        (retryAll)="onRetryAll()"
        (done)="onDone()"
      ></app-upload-documents-buttons>
    </iov-button-group>
  </div>

  @if (step === uploadStepEnum.SELECT_FILES) {
    <iov-upload-files-box
      [multiple]="true"
      [footerLeftText]="uploadBoxFooterLeftText"
      [footerRightText]="uploadBoxFooterRightText"
      (filesSelected)="onFilesSelected($event)"
    ></iov-upload-files-box>

    <iov-document-list
      [documentTypeOptions]="documentTypeOptions"
      [files]="files"
      (clickViewFile)="onViewFile($event)"
      (clickRemoveFile)="onRemoveFile($event)"
    ></iov-document-list>
  }

  @if (step >= uploadStepEnum.UPLOAD_START) {
    <iov-document-upload-status
      [uploadStatusTexts]="uploadStatusTexts()"
      [files]="files"
      (clickRetryUploadEvent)="onUploadWithRetry($event)"
      (clickViewFile)="onViewFile($event)"
    ></iov-document-upload-status>
  }
</div>

<div *ngIf="isShowDialogButtons" class="slide-over-buttons">
  <app-upload-documents-buttons
    [step]="step"
    doneButtonText="Skip failed uploads"
    [isSubmitButtonDisabled]="isSubmitButtonDisabled()"
    [isDoneButtonDisabled]="isDoneButtonDisabled()"
    (cancel)="onCancel()"
    (tryUploadAll)="onTryUploadAll()"
    (retryAll)="onRetryAll()"
    (done)="onDone()"
  ></app-upload-documents-buttons>
</div>
