<div class="upload-documents-buttons-container">
  @if (initial) {
    <iov-button-group>
      <iov-button (pressed)="cancel.emit()" [visible]="isShowCancelButton" type="ghost">
        Cancel
      </iov-button>
      <iov-button [disabled]="isSubmitButtonDisabled" (pressed)="tryUploadAll.emit()">
        <mat-icon icon-left>upload</mat-icon>
        Upload documents
      </iov-button>
    </iov-button-group>
  }
  @if (hasError) {
    <iov-button (pressed)="retryAll.emit()">
      <mat-icon icon-left>refresh</mat-icon>
      Retry all failed uploads
    </iov-button>
  }
  @if (finished) {
    <iov-button [disabled]="isDoneButtonDisabled" (pressed)="done.emit()">
      <mat-icon icon-left>check</mat-icon>
      {{ doneButtonText }}
    </iov-button>
  }
</div>
