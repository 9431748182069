<a
  class="form-link"
  (click)="$event.stopPropagation()"
  [class.hover-visibility-container]="openInNewTab()"
  [class.blue-link]="styledLink()"
  [class.maintain-font-size]="maintainFontSize()"
  [queryParamsHandling]="link().extras.queryParamsHandling"
  [queryParams]="link().extras.queryParams"
  [relativeTo]="link().extras.relativeTo"
  [routerLink]="link().commands"
  [target]="openInNewTab() ? '_blank' : '_self'"
>
  <ng-content></ng-content>
  @if (openInNewTab()) {
    <app-external-link-icon></app-external-link-icon>
  }
</a>
